// Logic specific to desktop browser website - mainly connected to RHS navigation menu.

// Provide browser's localStorage as pwStorage, providing a transient storage shim if necessary.
// Note, a cookie implementation really isn't worth doing anymore.
(function () {
    var prefix = $j(document.documentElement).data('storagePrefix') || "pw_";
    try {
        // In old browsers localStorage might throw a ReferenceError.
        // On Firefox (and elsewhere?) localStorage is null when disabled, rather than undefined.
        // In private browsing mode, even naming it might throw an illegal access error!
        var storage = localStorage;
        // In Safari, you'll get this far in private browsing mode but you can't actually write to localStorage.
        storage.setItem(prefix + 'test', 'test');
        storage.removeItem(prefix + 'test');
        // Safe to use localStorage!
        window.pwStorage = {
            setItem: function (key, value) {
                if (arguments.length < 2) {
                    throw new Error("Not enough arguments");
                }
                return storage.setItem(prefix + key, value);
            },
            getItem: function (key) {
                return storage.getItem(prefix + key);
            },
            removeItem: function (key) {
                return storage.removeItem(prefix + key);
            }
        };
    } catch (e) {
        // Error might be the one thrown above, or it might be some variant on "illegal access."
        // Or it might be a weird Firefox specific one...
        if (e.name == "NS_ERROR_FILE_CORRUPTED") {
            // Firefox SQLite severe breakage.
            window.addEventListener('load', function () {
                alert("Sorry, it looks like your browser storage has been corrupted."
                    + " Please clear your storage by going to "
                    + "Tools -> Clear Recent History -> Cookies and set time range to 'Everything'."
                    + " This will remove the corrupted browser storage across all sites.");
            });
        }
        var varStorage = {};

        window.pwStorage = {
            setItem: function (key, value) {
                if (arguments.length < 2) {
                    throw new Error("Not enough arguments");
                }
                varStorage[prefix + key] = value.toString();
            },
            getItem: function (key) {
                return (prefix + key) in varStorage ? varStorage[prefix + key] : null;
            },
            removeItem: function (key) {
                delete varStorage[prefix + key];
            }
        };
    }
})();


// Define an API for putting the menu in the correct state when page loads.
function pw_setActiveMenuItem($anchor) {
    $j(".left-nav-accordion li").removeClass("active");
    $anchor.closest('li').addClass('active'); // Are we supposed to do this always?
    var $menu = $anchor.closest('ul');
    if ($menu.hasClass('sub-menu')) {
        $menu.show();
        $menu.siblings('a').addClass('active');
    }
    // set appropriate visibility for 'Upgrade Now' button.
    setTimeout(function () {
        //alert("Button? "+($j('#navigation').height() + $j('#upgrade-now-button').height())+' '+$j('#left-sidebar').height());
        if ($j('#navigation').height() + $j('#upgrade-now-button').height() > $j('#left-sidebar').height()) {
            $j('#upgrade-now-button').hide();
        }
    }, 100);
}

function _pw_linkOrigin(link) {
    // Edge appears to be missing standard property of HTMLAnchorElement ?!
    return link.origin || link.protocol + '//' + link.host;
}

function pw_setCurrentLink(linkId) {
    var link = window.document.getElementById(linkId);
    if (link && _pw_linkOrigin(link) == window.location.origin) {
        window.pwStorage.setItem('currentLink', linkId);
    }
}

function pw_getCurrentLink() {
    var linkId = window.pwStorage.getItem('currentLink');
    if (linkId) {
        var link = window.document.getElementById(linkId);
        if (link && _pw_linkOrigin(link) == window.location.origin) {
            return linkId;
        }
    }
    return null;
}

// Browser Preferences API
function pw_getPreferences(system) {
    return $j(document.body).data(system + '-preferences');
}

function pw_updatePreferences(system, dict) {
    var preferences = pw_getPreferences(system);
    $j.extend(preferences, dict);
    $j(document.body).data(system + '-preferences', preferences);
    var saveUrl = $j(document.body).data('save-' + system + '-preferences-url');
    preferences.csrfmiddlewaretoken = $j('[name="csrfmiddlewaretoken"]').val();
    return $j.ajax(saveUrl, {type: 'POST', data: preferences});
}

function pw_getBrowserPreferences() {
    return pw_getPreferences('browser');
}

function pw_updateBrowserPreferences(dict) {
    return pw_updatePreferences('browser', dict);
}

function pw_csvForObject(o) {
    return Object.keys(o).filter(function (key) {
        return o[key];
    }).join(',');
}

function pw_objectForCsv(csv) {
    var o = {};
    (csv || '').split(',').forEach(function (word) {
        if (word) {
            o[word] = true;
        }
    });
    return o;
}

// Location API
window.pw_getLocationById = function (id) {
    var loc;
    $j('#location-menu a.location').each(function () {
        var d = $j.extend({name: $j(this).text()}, $j(this).data());
        if (d.id == id) {
            loc = d;
            return false;
        }
        return true;
    });
    return loc;
};
window.pw_getLocations = function () {
    var locs = [];
    $j('#location-menu a.location').each(function () {
        var d = $j.extend({name: $j(this).text()}, $j(this).data());
        locs.push(d);
    });
    return locs;
};
window.pw_setCurrentLocationById = function (id) {
    var loc = window.pw_getLocationById(id);
    if (loc) {
        window.pw_setCurrentLocation(loc);
    }
};
// FIXME: remove following function when no longer needed.
window.pw_showUpdateBasedOnTimestamps = function (pwe, pwg, ecmwf, gfs) {
    if (!arguments.length || (!pwe && !pwg && !ecmwf && !gfs)) {
        $j('.nextUpdate').hide();
        return;
    }
    var location = pw_getCurrentLocation();
    var delay = 12 * 3600; // Expected time between forecasts
    // Note, jQuery.map filters out undefined and null
    var nextUpdateInfo = window.pw_updateInfoToDisplayFromTimestamps([pwe, pwg, ecmwf, gfs]);
    $j('.nextUpdate-link').text(nextUpdateInfo.text);
    $j('.nextUpdate').show();

    $j.ajax("/localtime", {
        type: "GET",
        dataType: "json",
        traditional: true,
        data: {
            ma: location.id,
            t: $j.map([pwe, pwg, ecmwf, gfs], function (x) {
                return x ? [x, x + delay] : x;
            })
        }
    }).done(function (response) {
        //alert('For ' + location.name + ': ' + JSON.stringify(response));
        if (response.status == "OK") {
            $j('#hintContainer_nextUpdate').show();
            var timeElements = $j('.nextUpdate-time');
            $j.each([pwe, pwg, ecmwf, gfs], function (i, value) {
                // Note: the response ONLY contains times for original arguments that are truthy so be careful consuming it.
                if (value) {
                    timeElements.eq(2 * i).text(response.times.shift()).closest('tr').show();
                    timeElements.eq(2 * i + 1).text(response.times.shift());
                } else {
                    timeElements.eq(2 * i).closest('tr').hide();
                }
            });
            $j('.nextUpdate-tzinfo').text(location.name + " (" + response.timezone.replace('_', ' ') + ")");
        } else {
            $j('#hintContainer_nextUpdate').hide();
            window.Sentry && Sentry.captureMessage("Timezone conversion failure: " + (response.error || JSON.stringify(response)));
        }
    });
};
(function () {
    var currentLocation = JSON.parse(window.pwStorage.getItem('currentLocation'));
    window.pw_getCurrentLocation = function () {
        if (!currentLocation) {
            console.log("No known current location, attempting to correct that.");
            var first_location = $j('#location-menu a.location').first();
            if (first_location.length) {
                currentLocation = first_location.data();
                $j('#location-name').text((currentLocation.name = first_location.text()));
                first_location.parent().addClass("currentLocation");
            } else {
                throw new Error("Current location requested but there are no locations.");
            }
        }
        return currentLocation;
    };
    window.pw_setCurrentLocation = function (location) {
        // location = {name:*, id:*};
        // Don't call the func to update the page, but do change the name showing.
        location.name = location.name.toString().slice(0, 15);
        currentLocation = location;
        window.pwStorage.setItem('currentLocation', JSON.stringify(currentLocation));
        $j('#location-name').text(location.name);
        $j('#location-menu li').removeClass("currentLocation");
        $j('#location-menu li a[data-id=' + location.id + ']').parent().addClass("currentLocation");
    };
    jQuery(function () {
        var currentLocationFound = false;
        $j('#location-menu a.location').each(function () {
            // Display the current location
            var d = $j(this).data();
            if (!currentLocation || currentLocation.id == d.id) {
                currentLocation = $j.extend(currentLocation, d);
                $j('#location-name').text((currentLocation.name = $j(this).text()));
                $j(this).parent().addClass("currentLocation");
                currentLocationFound = true;
                return false;
            }
        });
        if (!currentLocationFound) {
            // Use the first one, assuming there even is one.
            var first = $j('#location-menu a.location').first();
            if (first.length) {
                var d = $j.extend({name: first.text()}, first.data());
                pw_setCurrentLocation(d);
                if (window.pw_updatePageForNewLocation) {
                    window.pw_updatePageForNewLocation(currentLocation);
                }
            }
        }

        // Top level navigation items... mostly then open (or close) a submenu.
        $j('.accordion > li > a:not(.nextUpdate-link)').on('click', function (event) {
            // for a .no_js item, just follow the link.
            if ($j(this).hasClass("no_js")) {
                if (this.id) {
                    pw_setCurrentLink(this.id);
                }
                return true;
            }
            // Otherwise disable normal navigation.
            event.preventDefault();
            // Special case: detect Forecast menu with no items and go to add location instead.
            var item = $j(this).closest('li');
            if (item.hasClass('forecasts')) {
                if (item.find('ul').children().length === 0) {
                    $j('#location-search div').trigger('click');
                    return;
                }
            }
            // Show and hide submenu items on click
            // When one opens, any other open ones close.
            if (!$j(this).hasClass('active')) {
                $j(this).next().stop(true, true).slideToggle('fast');
                $j('.accordion > li > a.active').removeClass('active').parent().find('.sub-menu').slideUp('fast');
                $j(this).addClass('active');
            } else {
                $j(this).parent().find('.sub-menu').slideUp('fast');
                $j(this).removeClass('active');
            }
            setTimeout(function () {
                if ($j('#navigation').height() + $j('#upgrade-now-button').height() > $j('#left-sidebar').height()) {
                    $j('#upgrade-now-button').hide();
                } else {
                    $j('#upgrade-now-button').show();
                }
            }, 500);
        });
        // Next level. Case by case, but always set current link.
        $j('.accordion ul li a').on('click', function () {
            if (this.id) {
                pw_setCurrentLink(this.id);
            }
            return true;
        });
        $j('#observations-link').on('click', function (event) {
            // This link has class "no_js" so the other handler will do nothing...
            // If we don't have a current location we can leave it out.
            event.preventDefault();
            if (currentLocation) {
                window.location = $j(this).data("base-url") + "/" + (currentLocation.paid || "");
            } else {
                $j('#location-search div').trigger('click');
            }
        });

        $j('#forecast-alerts-link').on('click', function (event) {
            event.preventDefault();
            if (currentLocation) {
                window.location = $j(this).data('base-url').replace('00', currentLocation.paid);
            } else {
                $j('#location-search div').trigger('click');
            }
        });
        $j('#validation-link').on('click', function (event) {
            event.preventDefault();
            var purchaseAreaId = currentLocation && currentLocation.paid;
            if (purchaseAreaId) {
                window.location = $j(this).data("base-url").replace("00", purchaseAreaId);
            }
        });

        // settings menu & adding new location
        $j('#settings-wrap li.upgrade-status, #location-search').on('click', function (event) {
            window.location = $j(this).data("url");
        });

        /* selecting a location */
        $j('#location-menu>li:not(.loc-del-confirm):not(.add-new-location)').on('click', function (event) {
            var anchor = $j(this).find('a.location');
            if (!anchor.length) return;
            var loc = $j.extend({name: anchor.text()}, anchor.data());
            if (!currentLocation || currentLocation.id != loc.id) {
                pw_setCurrentLocation(loc);
                if (window.pw_updatePageForNewLocation) {
                    window.pw_updatePageForNewLocation(currentLocation);
                }
                // Some pages aren't really location centric, so do nothing.
            }
        });

        // The location menu is revealed using css :hover, but will work a bit better with a little delay
        $j('#left-top-nav').on('mouseout', function (event) {
            $j('#location-menu').addClass("encouraged");
            $j('#hintContainer_nextUpdate').addClass("discouraged");
            setTimeout(function () {
                $j('#location-menu').removeClass("encouraged");
                $j('#hintContainer_nextUpdate').removeClass("discouraged");
            }, 600);
        });

        /* deleting location with the X button */
        $j('.delete-location.show-del-confirm').on('click', function (event) {
            event.stopPropagation(); // don't bubble up to li and select a location!
            var loc = $j(this).closest('li');
            loc.addClass("active").next().addClass("active");
            // timeout needed for transitions to take effect
            setTimeout(function () {
                loc.next().children('.delete-location.yes').addClass('slide');
            }, 50);
        });
        $j('.delete-location.no').on('click', function (event) {
            event.stopPropagation(); // don't bubble up to li and select a location!
            var confirm = $j(this).closest('li');
            confirm.children('.delete-location.yes').removeClass('slide');
            // timeout needed for transitions to take effect
            setTimeout(function () {
                confirm.removeClass("active").prev().removeClass("active");
            }, 100);
        });
        $j('.delete-location.yes').on('click', function (event) {
            event.stopPropagation(); // don't bubble up to li and select a location!
            var confirm = $j(this).closest('li');
            var loc = confirm.prev().find('a.location');
            var purchase_location_id = loc.data('paid');
            $j.ajax({
                url: "/location/delete/" + purchase_location_id, // FIXME: Somehow use django url tag?
                success: function (data, textStatus, jqXHR) {
                    // 1) Remove the location from the menu
                    confirm.remove();
                    loc.closest('li').remove();
                    // 2) If necessary, choose a new location.
                    if (purchase_location_id == pw_getCurrentLocation().paid) {
                        var new_loc = $j('#location-menu>li:not(.loc-del-confirm):not(.add-new-location)').first();
                        if (new_loc.length) {
                            new_loc.trigger('click');
                        } else {
                            window.pwStorage.removeItem('currentLocation');
                            $j('#location-search div').trigger('click');
                        }
                    }
                }
            });
        });
        $j('.predictcurrent-location[data-url]').on('click', function (event) {
            window.location = $j(this).data('url');
        });
        // set top nav bar wide enough to show all tabs
        var minWidth = 0;
        $j('#header-nav-routing li').each(function () {
            minWidth += $j(this).outerWidth();
        });
        $j('#header').css('min-width', $j('#header-nav-routing ul').css('min-width', minWidth + 'px').outerWidth() + 'px');

        // Set up toggle click data listener
        $j('[data-toggleclass]').on('click', function toggleDiv() {
            var toggleName = $j(this).attr('data-togglename');
            var toggleClass = $j(this).attr('data-toggleclass');
            $j(this).addClass(toggleName);
            if ($j(this).hasClass(toggleClass)) {
                $j("." + toggleName).removeClass(toggleClass);
            } else {
                $j("." + toggleName).addClass(toggleClass);
            }
        });
        (function () {
            // Set up response of next update panel to mouse
            var in_next_update_link = false;
            var in_next_update_panel = false;
            $j('.nextUpdate').on('mouseenter', function () {
                in_next_update_link = true;
                setTimeout(showOrHideNextUpdate, 100);
            }).on('mouseleave', function () {
                in_next_update_link = false;
                setTimeout(showOrHideNextUpdate, 100);
            });
            $j('#hintContainer_nextUpdate').on('mouseenter', function () {
                in_next_update_panel = true;
                setTimeout(showOrHideNextUpdate, 100);
            }).on('mouseleave', function () {
                in_next_update_panel = false;
                setTimeout(showOrHideNextUpdate, 100);
            });

            function showOrHideNextUpdate() {
                $j('#hintContainer_nextUpdate').toggleClass('active', in_next_update_link || in_next_update_panel);
            }

        })();
        $j("#settings-wrap")
            .on('click', function () {
                $j(".settings ~ ul").toggle();
            })
            .on('mouseleave', function () {
                $j(".settings ~ ul").hide();
            });
        $j(".language-current").on('click', function () {
            $j(".language-children").show();
        });
        $j("#language-switcher").on('mouseleave', function () {
            $j(".language-children").hide();
        });
    });
})();
