(function(){
    var displayInterval, displayDelay = 20000;
    var refreshTimeout;
    var skew, nextUpdate;
    var mapAreaId, resolution, callback;
    var domains, timezoneName;
    var sourceTimes;

    function secondsToText(secs, verbose) {
        if (secs === '' || secs == null) return '';
        if (secs <= 0) {
            if (verbose) {
                return '<strong>' + gettext("Some models are delayed") + '</strong>'
            } else {
                return gettext('Some models are delayed, hover for details')
            }
        }
        var hours = Math.floor(secs/3600);
        secs -= 3600*hours;
        var minutes = Math.round(secs/60);
        if (verbose) {
            return (
                gettext("All atmospheric models expected to update in")
                + '\n<strong>'
                + interpolate(gettext("%(x_hours)s and %(y_minutes)s"), {
                    x_hours: interpolate(ngettext("%s hour", "%s hours", hours), [hours]),
                    y_minutes: interpolate(ngettext("%s minute", "%s minutes", minutes), [minutes])
                }, true)
                +'</strong>'
            );
        }
        return hours < 1 ? interpolate(ngettext("All forecasts expected to update in %s minute", "All forecasts expected to update in %s minutes", minutes), [minutes])
            : interpolate(ngettext("All forecasts expected to update in %s hour",   "All forecasts expected to update in %s hours", hours), [hours]);
    }
    function redisplay() {
        var seconds = nextUpdate - (Date.now()/1000) + skew;
        $j('.nextUpdate').show();
        $j('.nextUpdate-link').text(secondsToText(seconds));
        $j('.nextUpdate-title').html(secondsToText(seconds, true));
    }
    function showSources(sourceTimes) {
        for (var src in sourceTimes) { // noinspection JSUnfilteredForInLoop
            var d = sourceTimes[src];
            var row = $j('tr.nextUpdate-' + src);
            if (d) {
                var spans = row.find('.nextUpdate-time');
                spans.eq(0).text(d['lastUpdateLocalTime']);
                spans.eq(1).text(d['nextUpdateLocalTime']);
                row.show();
            } else {
                row.hide();
            }
        }
    }
    function success(data) {
        skew = Date.now()/1000 - data['currentTime'];
        console.log(data);
        var location = pw_getCurrentLocation();
        if (location.id !== mapAreaId) {
            // Oops!
            // Change of location should have triggered its own refresh so do nothing
            console.log("Callback for previous location - stop processing.");
            return;
        }
        if (data['resolution'] !== resolution) {
            if (resolution === 60 && data['resolution'] === 50) {
                // That's ok, keep going.
            } else {
                console.log("Callback for previous resolution - stop processing.");
                return;
            }
        }
        timezoneName = data["timezone"];
        domains = data["domains"];
        $j('.nextUpdate-tzinfo').text(location.name + " (" + data["timezone"].replace('_',' ') + ")");
        $j('#hintContainer_nextUpdate').show();

        for (var src in data['sources']) { // noinspection JSUnfilteredForInLoop
            if (sourceTimes && sourceTimes[src]) { // noinspection JSUnfilteredForInLoop
                if (data['sources'][src]['lastUpdateAnalysisTime'] > sourceTimes[src]['lastUpdateAnalysisTime']) {
                    console.log(src + " has been updated.");
                    if (callback) { // noinspection JSUnfilteredForInLoop
                        callback(src);
                    } else {
                        console.log("So let's reload the page.");
                        window.location.reload();
                        return; // no point doing further work
                    }
                }
            }
        }
        sourceTimes = data['sources'];
        showSources(sourceTimes);
        $j('.nextUpdate-title').attr('title', '' + data['nextUpdateModel'] + ' ' + data['nextUpdateLocalTime']);
        // Update the hours and minutes display now, and then every so often
        nextUpdate = data['nextUpdate'];
        redisplay();
        displayInterval = setInterval(redisplay, displayDelay);
        // Schedule the next refresh
        var minimumDelay = 120000; // Two minutes
        var randomDelay = Math.random() * minimumDelay; // Somewhere between 0 and 1 times minimumDelay
        var milliSecondsToNextEvent = 1000*data['nextEvent'] - Date.now() + skew*1000;
        var milliSecondsToNextRefresh =  Math.max(minimumDelay, milliSecondsToNextEvent) + randomDelay;
        console.log("Refreshing update information again in " + Math.round(milliSecondsToNextRefresh/1000) + " seconds");
        clearTimeout(refreshTimeout);
        refreshTimeout = setTimeout(refresh, milliSecondsToNextRefresh);
    }
    function error(e) {
        console.error(e);
        $j('.nextUpdate').hide();
        $j('#hintContainer_nextUpdate').hide();
        // Schedule the next refresh
        var minimumDelay = 120000; // Two minutes
        var randomDelay = Math.random() * minimumDelay;
        var milliSecondsToNextEvent = minimumDelay + randomDelay;
        clearTimeout(refreshTimeout);
        refreshTimeout = setTimeout(refresh, milliSecondsToNextEvent);
    }
    function refresh() {
        console.log("Initiating next updates refresh.");
        clearInterval(displayInterval);
        var params = {
                ma: mapAreaId,
                r: resolution
            };
        if (domains) {
            params.d = JSON.stringify(domains);
        }
        if (timezoneName) {
            params.tz = timezoneName;
        }
        $j.ajax($j('.nextUpdate').data('update-status-url'), {data: params, success:success, error:error});
    }
    $j(function() {
        $j('.nextUpdate').on('dblclick', function () {
            clearTimeout(refreshTimeout);
            refreshTimeout = setTimeout(refresh, 2000);
        }); // for debugging, mostly
    });
    if (!window.app) {
        window.PW_resetUpdatesWithResolution = function (resolution_, callback_) {
            console.log('Setting up updates for resolution ' + resolution_ + 'k');
            try {
                mapAreaId = pw_getCurrentLocation().id;
            } catch (e) {
                console.warn(e);
                return;
            }
            resolution = resolution_;
            callback = callback_;
            domains = undefined;
            timezoneName = undefined;
            clearTimeout(refreshTimeout);
            refreshTimeout = setTimeout(refresh, 2000); // Wait 2 seconds to give the rest of the page a chance to settle down.
        };
    } else {
        // On smartphone, this script is only loaded for the dedicated next update page. So we put the page logic here.
        function currentNextUpdatesKey() {
            var mapAreaId = pw_getCurrentLocation().id;
            return mapAreaId && "Updates-L" + mapAreaId;
        }
        function updatesReset() {
            var locations = app.getData('Locations');
            var updates;
            if (locations && locations.length && (updates = app.getData(currentNextUpdatesKey()))) {
                app.enableWebView();
                $j('.nextUpdate-table').show();
                $j('.no-locations').hide();
                showSources(updates['sources']);
                var timezoneName = updates["timezone"];
                var location = pw_getCurrentLocation();
                $j('.nextUpdate-tzinfo').text(location.name + " (" + timezoneName.replace('_',' ') + ")");
            } else {
                $j('.nextUpdate-table').hide();
                if (locations && !locations.length) {
                    $j('.no-locations').show();
                } else {
                    app.disableWebView();
                }
            }
        }
        app.addSettingsListener(function nextUpdateTableSettingsListener(upd /* , old, byRequest */) {
            // By convention, we already have Locations data when we get here.
            if ('App_CurrentLocation' in upd) {
                app.requestData([currentNextUpdatesKey()]);
            }
            if ('App_TimeUntilNextUpdate' in upd) {
                $j('.nextUpdate-title').html(secondsToText(upd['App_TimeUntilNextUpdate'], true));
            }
        });
        app.addUpdatedDataListener(function nextUpdateTableUpdatedDataListener(updatedDataKeys /*, notifier */) {
            var requestKeys = ['Locations', currentNextUpdatesKey()].filter(function(key) {
                return updatedDataKeys.indexOf(key) > -1;
            });
            app.requestData(requestKeys);
        });
        app.addReceivedDataListener(function nextUpdateTableReceivedDataListener(receivedData /*, receivedTimestamps, notifier */) {
            if (app.getSetting("App_CurrentLocation") && currentNextUpdatesKey() in receivedData) {
                updatesReset();
            }
        })
    }
})();
