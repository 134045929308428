var GLOBAL_hintData = {};

function setPageHints(hintData) {
    GLOBAL_hintData = hintData;
    setTimeout(function() {
            updateHintsLink();
            updateHints();
        }, 200);
}

function showAllHints() {
    pw_updateBrowserPreferences({ hiddenHintsCsv:'' });
    updateHintsLink();
    updateHints();
}

function hideAllHints() {
    pw_updateBrowserPreferences({ hiddenHintsCsv:'__all__' });
    updateHintsLink();
    updateHints();
}

function updateHints() {    
    return updateHints_real(GLOBAL_hintData);
}

function isHintHidden(name) {
    var prefs = pw_getBrowserPreferences();
    return prefs.hiddenHintsCsv == '__all__' || prefs.hiddenHintsCsv.split(',').indexOf(name) != -1;
}

function hintHiddenByPage(hintId) {
    var hintVisibleOverrideVar = 'hint_'+hintId+'_visible'; 
    if (typeof window[hintVisibleOverrideVar]!='undefined' && !window[hintVisibleOverrideVar]) {
        return true;
    }
    return false;
}

function setHintHidden(name) {
    if (!isHintHidden(name)) {
        var csv = pw_getBrowserPreferences().hiddenHintsCsv;
        pw_updateBrowserPreferences({ hiddenHintsCsv:csv + (csv && ',') + name });
    }
}


function updateHint_real(hints, pageId, id) {
    var anchor = document.getElementById('hint_'+id);
    if (!anchor) {
        return false;
    }
    
    var hintName = pageId + '_' + id;
    var isVisible = !isHintHidden(hintName);
    var hint = hints[id];
    
    if (typeof anchor.bubble != 'undefined') {
        if ((!hint.allowClose || isVisible) && hint.hintBehaviour!='disabled') {
            if (!hintHiddenByPage(id)) {
                anchor.bubble.style.display = '';
            } else {
                anchor.bubble.style.display = 'none';
            }
            anchor.bubble.displaySetting = '';
        } else {
            anchor.bubble.style.display = 'none';
            anchor.bubble.displaySetting = 'none';
        }
    } else {
        anchor.className = "hintAnchor";
        
        var content = document.createElement('div');
        content.innerHTML = hint.hintText;

	if (hint.hintType == '') {
	    content.className = "notification-content";
	    var bubble = $j('<div>').addClass('notification-box padded info').append(content).appendTo(anchor)[0];
	    if (hint.allowClose) {
		bubble.close = document.createElement('div');
		bubble.close.className = 'tip-close';
		content.appendChild(bubble.close);    
                bubble.close.onclick = (function(hintName, bubble) { return function(){
                    setHintHidden(hintName);
                    bubble.style.display = 'none';
                    bubble.displaySetting = 'none';
                    updateHintsLink();
                }; })(hintName, bubble);
                if (isHintHidden(hintName)) {
                    bubble.style.display = 'none';
                    bubble.displaySetting = 'none';
                }
	    } else {
                $j(bubble).removeClass("info").addClass("alert");
            }
	    // FIXME copied and pasted
	    anchor.bubble = bubble;
	    bubble.anchor = anchor;
	    return true;
	}
	// Will need a little icon to click on that is a child of the anchor.
	if ($j(anchor).css('position') == 'static') {
	    $j(anchor).css('position','relative');
	}
	anchor.icon = $j('<div>').addClass("tip-wrapper intro").append($j('<div>').addClass('icon')).appendTo(anchor)[0];
	
        content.style.width = hint.width+'px';
        content.style.height = hint.height+'px';
        content.className = hint.hintType;
    
        // get real width/height
        content.style.visibility = 'hidden';
        document.body.appendChild(content);
        var w = content.offsetWidth || content.clientWidth;
        var h = content.offsetHeight || content.clientHeight;
        document.body.removeChild(content);
        content.style.visibility = 'visible';
    
        // get anchor x/y
        var x = 0;
        var y = 0;
        var e = anchor;
        while (e) {
            x += e.offsetLeft;
            y += e.offsetTop;
            e = e.offsetParent;
        }
        var px = x;
        var py = y;
        var anchorW = anchor.offsetWidth || anchor.clientWidth;
        var anchorH = anchor.offsetHeight || anchor.clientHeight;
        
        var isLeft = (hint.hintType.indexOf('hintLeft') != -1);
        var isRight = (hint.hintType.indexOf('hintRight') != -1);
        var isAbove = (hint.hintType.indexOf('hintAbove') != -1);
        var isBelow = (hint.hintType.indexOf('hintBelow') != -1);
                                                    
        var ox = 0;
        var oy = 0;
        if (isLeft) {
            ox = -w - 20 - 19;
            x += ox;
        }
        if (isRight) {
            ox = 19;
            x += ox;
        }                    
        if (isBelow) {
            oy = 19;
            y += oy;
        }                    
        if (isAbove) {
            oy = -19 - h;
            y += oy;
        }
                            
        if (isLeft || isRight) {
            y += hint.nudgeY*1;
            oy += hint.nudgeY*1;
            if (oy > -13) {
                y += -13 - oy;
                oy = -13;
            }
            if (oy < 13 - h) {
                y += 13 - h - oy;
                oy = 13 - h;
            }
        }
        
        if (isAbove || isBelow) {
            x += hint.nudgeX*1;
            ox += hint.nudgeX*1;
            if (ox > -13) {
                x += -13 - ox;
                ox = -13;
            }
            if (ox < - w - 7) {
                x += - w - 7 - ox;
                ox = - w - 7;
            }
        }
        
        x += hint.pointX;
        y += hint.pointY;
        if (isRight) {
            x += anchorW;
        }
        if (isBelow) {
            y += anchorH;
        }
        if (isAbove) {
            y -= anchorH;
        }
                            
        bubble = createBubble(hint.allowClose);
        bubble.anchor = anchor;
        anchor.bubble = bubble;
        bubble.hintId = id;
        bubble.x = x;
        bubble.y = y;
        bubble.px = px;
        bubble.py = py;
        bubble.displaySetting = '';
        

        var iconX = x, iconY = y;
        var e = anchor;
        var isFixed = false;
        while (e) {
            if ($j(e).css('position') == 'fixed') {
                isFixed = true;
            }
            iconX -= e.offsetLeft;
            iconY -= e.offsetTop;
            e = e.offsetParent;
        }
        if (isBelow || isAbove) {
            iconX += hint.width/2;
        }
        if (isLeft||isRight) {
            iconY += hint.height/2;
        }
        iconX += 5;
        iconY += 5;
        if (isBelow) {
            iconY -= 50;
        }
        if (isAbove) {
            //iconY += 50;
            // Why isn't this necessary for hintBelow? I don't know.
            iconY = hint.pointY;
        }
        if (isRight) {
            iconX -= 50;
        }
        if (isLeft) {
            iconX += 50;
        }
        
        $j(anchor.icon).css({top:iconY+'px',left:iconX+'px'})

        // adjust height and width for the padding and border
        // FIXME: magic numbers duplicated from CSS
        var extra = 2*27 + 2;



	bubble.id = "hintContainer_"+id;
        bubble.className = 'hintContainer '+hint.hintType;

	var sheet = $j('<style>',{type:"text/css"});
	sheet.text(
	    "#"+bubble.id+"{"
                +"position: " + (isFixed? 'fixed':'absolute') +';'
		+"left:"+x+"px;"
		+"top:"+y+"px;"
		+"width:"+((isAbove || isBelow) ? (w+extra) : '0' )+'px;'
		+"height:"+((isAbove || isBelow) ? '0' : (h+extra) )+'px;'
		+"}"
		+"#"+bubble.id+'.active {'
		+"width:"+(w+extra)+'px;'
		+"height:"+(h+extra)+'px;'
		+"}"
	).appendTo($j('body'));
        if (hintHiddenByPage(id)) {
            bubble.style.display = 'none';                           
        }
        
        if (hint.allowClose) {
            bubble.close.onclick = (function(hintName, bubble) { return function(){
                setHintHidden(hintName);
                bubble.style.display = 'none';
                bubble.displaySetting = 'none';
                updateHintsLink();
            }; })(hintName, bubble);
        }
        
        if (hint.hintBehaviour == 'static') {
            bubble.className += ' active';
        }

        // use jQuery because I'm in the habit.
        $j(bubble).on('click', function(event) {
            event.stopPropagation();
            $j(this).toggleClass("active");
        });
	$j(anchor.icon).on('click', function(event) {
            event.stopPropagation();
	    $j(bubble).toggleClass('active');
	});
    
        // update position as required
        bubble.updater = setInterval(function()
            {
                var e = bubble.anchor;
                var x = 0;
                var y = 0;
                while (e) {
                    x += e.offsetLeft;
                    y += e.offsetTop;
                    e = e.offsetParent;
                }
                
                if (hintHiddenByPage(bubble.hintId)) {
                    bubble.style.display = 'none';
                    if (anchor.icon) {
                        anchor.icon.style.display = 'none';
                    }
                } else {
                    bubble.style.display = bubble.displaySetting;
                    if (anchor.icon && anchor.icon.style) {
                        anchor.icon.style.display = bubble.displaySetting;
                    }
                }
                
                var dx = x - bubble.px; 
                var dy = y - bubble.py; 
                if (dx || dy) {
                    bubble.x += dx;
                    bubble.y += dy;
                    bubble.px += dx;
                    bubble.py += dy;
                    bubble.style.left = bubble.x+'px';
                    bubble.style.top = bubble.y+'px';                        
                }
            }, 2000);
        
        bubble.contentArea.appendChild(content);
        if ((!hint.allowClose || isVisible) && hint.hintBehaviour!='disabled') {
            if (!hintHiddenByPage(id)) {
                anchor.bubble.style.display = '';
            } else {
                anchor.bubble.style.display = 'none';
                if (anchor.icon) {
                    anchor.icon.style.display = 'none';
                }
            }
            anchor.bubble.displaySetting = '';
        } else {
            anchor.bubble.style.display = 'none';
            if (anchor.icon) {
                anchor.icon.style.display = 'none';
            }
            anchor.bubble.displaySetting = 'none';
        }
        document.body.appendChild(bubble);
    }
    return true;
}

function updateHint(hints, pageId, id) {
    var success = false;
    try {
        success = updateHint_real(hints, pageId, id);
    } catch (ex) {
    }
    if (!success) {
        setTimeout(function(){ updateHint(hints, pageId, id); }, 1000);
    }
}

function updateHints_real(pageHints) {
    for (var pageId in pageHints) {
        hints = pageHints[pageId];
        for (var id in hints) {
            updateHint(hints, pageId, id);
        }
    }
}

var GLOBAL_hintLinkRetryCounter = 0;

function updateHintsLink() {    
    var hintToggleLink = document.getElementById('id_helpShowHints');
    if (!hintToggleLink) {
        setTimeout(updateHintsLink, 200)
        return;
    }

    if (pw_getBrowserPreferences().hiddenHintsCsv != '') {
        hintToggleLink.innerHTML = 'Show Hints';
        hintToggleLink.onclick = showAllHints;
    } else {
        hintToggleLink.innerHTML = 'Hide Hints';
        hintToggleLink.onclick = hideAllHints;
    }
}

function createBubble(showClose) {
    var bubble = document.createElement('div');
    bubble.contentArea = document.createElement('div');
    bubble.contentArea.className = 'hintContent';
    bubble.contentArea.style.zIndex = 500;
    bubble.appendChild(bubble.contentArea);
    if (showClose){
        bubble.close = document.createElement('div');
        //bubble.close.innerHTML = '&#9747;'
        bubble.close.className = 'hintClose';
        bubble.close.style.zIndex = 500;
        bubble.appendChild(bubble.close);    
    }
    return bubble;
}

// Apply hints
jQuery(function($) {
    setPageHints($(document.body).data('page-hints'));
});
